// services/api.js
import axios from 'axios';
import API_BASE_URL from './baseurl';

/*
    Branch Services

  - Fetch All Branches
  - Fetch Branch Details By Url

*/ 
// Function to fetch all branches 
export const fetchAllBranches = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/branches`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all branches:", error);
        throw error;
    }
};

// Function to fetch branch details by URL
export const fetchBranchByUrl = async (branchUrl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/branches/${branchUrl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching branch Details by URL:", error);
         // Return error response if available
         if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
};


/*
      Course Services

    - Fetch All Courses
    - Fetch Course Details By Url
    - Fetch Course Details By Url And Branch Id
*/ 
// Function to fetch all Courses
export const fetchAllCourses = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all Courses:", error);
        throw error;
    }
};

// Function to fetch Course details by URL
export const fetchCourseByUrl = async (courseurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course-details/${courseurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Course Details by URL:", error);
          // Return error response if available
          if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
};

// Function to fetch Course details by URL And Branch ID
export const fetchCourseByUrlBrach = async (courseurl,id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course-details/${courseurl}/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Course details by URL And Branch ID:", error);
         // Return error response if available
         if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
};

/*
      Blogs Services
      
      - Fetch All Blogs
      - Fetch More Blogs
      - Fetch Blog Details By Url
      - Fetch All Blogs Using Date
      - Fetch More Blogs Using Date
*/ 
// Function to fetch all blogs
export const fetchAllBlogs = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
        throw error;
    }
}

// Function to fetch More blogs
export const fetchMoreBlogs = async (limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching More blogs:", error);
        throw error;
    }
}

// Function to fetch all blogs using Date
export const fetchAllBlogsDate = async (s1,e1) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs/${s1}/${e1}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs using Date:", error);
        throw error;
    }
}

// Function to fetch More blogs using Date
export const fetchMoreBlogsDate = async (s1,e1,limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs/${s1}/${e1}/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching More blogs using Date:", error);
        throw error;
    }
}

// Function to fetch blog Details by URL
export const fetchBlogByUrl = async (blogurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blog/${blogurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching blog Details by URL:", error);
         // Return error response if available
         if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
}

/*
        Articles Services
      
        - Fetch All Articles
        - Fetch More Articles
        - Fetch Article Details By Url
        - Fetch All Articles Using Date
        - Fetch More Articles Using Date
*/ 
// Function to fetch all articles
export const fetchAllArticles = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all articles:", error);
        throw error;
    }
}

export const fetchAllOldArticles = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/articles`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all articles:", error);
        throw error;
    }
}
// Function to fetch more Articles
export const fetchMoreArticles = async (limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching More articles:", error);
        throw error;
    }
}

// Function to fetch all articles using date
export const fetchAllArticlesDate = async (s1,e1) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair/${s1}/${e1}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all articles using date:", error);
        throw error;
    }
}

// Function to fetch More articles using date
export const fetchMoreArticlesDate = async (s1,e1,limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair/${s1}/${e1}/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching More articles using date:", error);
        throw error;
    }
}

// Function to fetch Article Details by URL
export const fetchArticlesByUrl = async (articleurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affairs/${articleurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Article Details by URL:", error);
        // Return error response if available
        if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
}

// Function to fetch Article Details by URL
export const fetchOldArticlesByUrl = async (articleurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/article/${articleurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Article Details by URL:", error);
        // Return error response if available
        if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
}
/*
        Common Services using in all Over Pages

        - Fetch Website related details
        - Fetch Function to Scrolling Important headlines at this website regarding important news and updated from upsc aspirants.
        - Fetch Navbar related details
*/ 
// Function to fetch Website related details
export const fetchAllWebdetails = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/webdetails`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Website related details:", error);
        throw error;
    }
}

// Function to Fecth Scrolling Important headlines at this website regarding important news and updated from upsc aspirants.
export const fetchAllNewsheadline = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/newsheadline`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Scrolling Important headlines at this website regarding important news and updated from upsc aspirants.:", error);
        throw error;
    }
}

// Function to Navbar related details Data
export const fetchNavbarDetails = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/topnavbar`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Navbar related details:", error);
        throw error;
    }
}

/*
        Home Services

        - Fetch Hero Banner Data
        - Fetch About Section Data For Home Page
        - Fetch Testimonials Data
        - Fetch Homepage Content Data
        - Fetch Faculty Data
        - Fetch Frequently Asked Questions Data
*/ 
// Function to Hero Bnnnaer Data
export const fetchHeroBnaner = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/headerbar`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Hero Banner Data:", error);
        throw error;
    }
}

// Function to About details Data for Home Page
export const fetchAboutsecctionbyHomepage = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/abouthome`);
        return response.data;
    } catch (error) {
        console.error("Error fetching About details Data for Home Page:", error);
        throw error;
    }
}

// Function to Testimonials Data
export const fetchTestimonials = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/testimonials`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Testimonials Data:", error);
        throw error;
    }
}

// Function to Home Content Data
export const fetchHomepageContent = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/indexpagecontent`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Home Content Data:", error);
        throw error;
    }
}

// Function to faculty Data
export const fetchfaculty = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/faculty`);
        return response.data;
    } catch (error) {
        console.error("Error fetching faculty Data:", error);
        throw error;
    }
}

// Function to frequently Asked Questions Data
export const fetchfaq = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/faq`);
        return response.data;
    } catch (error) {
        console.error("Error fetching frequently Asked Questions Data:", error);
        throw error;
    }
}

/*
        Upsc Services

        - Fetch Upsc Page Data
*/ 
// Function to Referal Policy Data
export const fetchPageByurl = async (pageurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc/${pageurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Referal Policy Data:", error);
         // Return error response if available
         if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
}

/*
        Interview Services
        
        - Fetch Interview Content Data
        - Fetch Interview Panel Data
*/
// Function to Interview Content Data
export const fetchInterviewContent = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/interviewcontent`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Interview Content Data:", error);
        throw error;
    }
}

// Function to Interview Panel Data
export const fetchInterviewPanel = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/interviewpanel`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Interview Panel Data:", error);
        throw error;
    }
}


/*
    Knowledge Center Services

        - Fetch Magazine Data
        - Fetch General-Essay Data
        - Fetch currentaffairs Data
        - Fetch More currentaffairs Data
        - Fetch Mcq Data
        - Fetch More Mcq Data
        - Fetch Video Category Data
        - Fetch Video Category Details By URL
        - Fetch More Video Category With Limit
        - Fetch All Video Category Using Date
        - Fetch More Video Category Using Date With Limit

*/
// Function to Magazine Data
export const fetchMagazine = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/magazine`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Magazine Data:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

// Function to General-Essay Data
export const fetchGeneralEssay = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/generalessay`);
        return response.data;
    } catch (error) {
        console.error("Error fetching General-Essay Data:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

// Function to currentaffairs Data
export const fetchcurrentaffairs = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/currentaffairs`);
        return response.data;
    } catch (error) {
        console.error("Error fetching currentaffairs Data:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

// Function to fetch more currentaffairs Data
export const fetchMorecurrentaffairs = async (limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/currentaffairs/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching more currentaffairs Data:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

// Function to Mcq
export const fetchMcq = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/mcq`);
        return response.data;
    } catch (error) {
        console.error("Error fetching MCQ:", error);
        throw error;
    }
}

// Function to fetch More MCQ with limit
export const fetchMoremcq = async (limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/mcq/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching More MCQ with limit:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

// Function to Video Category
export const fetchVideocategory = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/videocategory`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Video Category:", error);
        throw error;
    }
}

// Function to Video Category details by URL
export const fetchVideocategoryByUrl = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/video/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Video Category details by URL:", error);
         // Return error response if available
         if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
}

// Function to fetch more Video using id with limit
export const fetchMorevideocategory = async (id,limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/video/${id}/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching more Video using id with limit:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

// Function to fetch all video category data using date
export const fetchAllVideoCategroyDate = async (id,s1,e1) => { 
    try {
        const response = await axios.get(`${API_BASE_URL}/video/${id}/${s1}/${e1}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all video category data using date:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

// Function to fetch all video category data using date with limit
export const fetchMoreVideoCategorysDate = async (id,s1,e1,limit) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/video/${id}/${s1}/${e1}/${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all video category data using date with limit:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

/*
        Exploration Services

        - Fetch Exploration Data
        - Fetch Exploration Details By URL
*/
// Function to Exploreation Data
export const fetchExploreation = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/exploration`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Exploreation Data:", error);
        throw error;
    }
}

// Function to Exploreation details by URL
export const fetchExploreationByUrl = async (exploreurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/explore/${exploreurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Exploreation details by URL:", error);
         // Return error response if available
         if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
}

/*
    About Page Services

        - Fetch About Page Data

*/

// Function to fetch Test details by URL
export const fetchTestByUrl = async (testurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course-details/${testurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching Test details by URL:", error);
         // Return error response if available
         if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
}

// Function to fetch About details by URL
export const fetchAboutByUrl = async (abouturl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/about/${abouturl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching About details by URL:", error);
         // Return error response if available
         if (error.response) {
            return error.response; // Contains status, data, and headers
        }
        throw error;
    }
}

/*
        Books Services

        - Fetch books Data
        - Fetch books Data
*/

// Function to books Data
export const fetchbooks = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/books`);
        return response.data;
    } catch (error) {
        console.error("Error fetching books Data:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

export const fetchepics = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/appscMagazine`);
        return response.data;
    } catch (error) {
        console.error("Error fetching books Data:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

/*
        Meta title and Meta Description Services

        - Fetch books Data
*/

// Function to Meta title and Meta Description Data
export const fetchmetadetails = async (seopathname) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/seoterms/${seopathname}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching meta title and meta description Data:", error);
        // Return error response if available
        if (error.response) {
           return error.response; // Contains status, data, and headers
       }
       throw error;
    }
}

/*
        Footer Services

        - Fetch footer left Data
        - Fetch footer right Data
*/

// Function to footer left Data
export const fetchfooterleft = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/leftfooterbar`);
        return response.data;
    } catch (error) {
        console.error("Error fetching footer left Data:", error);
        throw error;
    }
}

// Function to footer right Data
export const fetchfooterright = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/rightfooterbar`);
        return response.data;
    } catch (error) {
        console.error("Error fetching footer right Data:", error);
        throw error;
    }
}

/*
        Query Form Services

        - Submit data using POST API
*/
// Function to submit data using POST API
export const submitQueryForm = async (name, contact, email, query, pagename, pagurl) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/queryform`, {
            name,
            contact,
            email,
            query,
            pagename,
            pagurl,
        });
        return response.data;
    } catch (error) {
        console.error("Error submitting contact form:", error);
        throw error;
    }
};

// Function to get call submit data using POST API
export const submitgetcallform = async (contact) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/getacall`, {
            contact
        });
        return response.data;
    } catch (error) {
        console.error("Error submitting get call form:", error);
        throw error;
    }
};


