import React, { useEffect } from 'react';
import logoimg from "../../Logo-1.png";
import { useWebdetailsContext } from '../../Context/WebDetails';
import { Link } from 'react-router-dom';
import { useFooterContext } from '../../Context/footerapi';
import { useScrollingManagementContext } from '../../Context/ScrollingEventManagement';
import { BorderTop, DisplaySettings, Padding } from '@mui/icons-material';
import { useCourseContext } from '../../Context/Courseapi';
import FloatingButton from '../FloatingButton';
import { useValidateUrlContext } from '../../Context/Validateurl';
import { useTrackingManagementContext } from '../../Context/TrackingManagement';
import { useQueryContext } from '../../Context/QueryManagement';

function Footer() {
    const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
    useWebdetailsContext();
    const { Footerleft, Footerright, fetchFooterleftHandler, fetchFooterrightHandler } =
    useFooterContext();
    const { ScrollingopenHandler } = useScrollingManagementContext();
    const { validateURL } = useValidateUrlContext();

    const {courses, fetchAllCoursesHandler} = useCourseContext();
    const { trackingDataValue, trackingid, handleButtonClick } = useTrackingManagementContext();
    
      const { getPagename, pagename } = useQueryContext();
          
            useEffect(() =>{
              getPagename("Footer")
               },[])

    useEffect(() => {
        fetchWebdetailsByUrlHandler(); // Fetch branches on component mount
        fetchFooterleftHandler();
        fetchFooterrightHandler();
        fetchAllCoursesHandler();
      }, []);
    
      const Mailid = Webdetails?.adminemail || "sriramsias@gmail.com";
      const phone = Webdetails?.callnumber || "";
      const flink = Webdetails?.facebook || "";
      const ilink = Webdetails?.instagram || "";
      const tlink = Webdetails?.twitter || ""; 
      const ylink = Webdetails?.youtube || "";  
      const footerstamp = Webdetails?.footerstamp || ""; 
      const androidapp = Webdetails?.androidapp || ""; 
      const iosapp = Webdetails?.iosapp || "";  
      const footerhtml = Webdetails?.footercontent || "";
      const dynamiclogo = Webdetails?.logo || logoimg;


  return (
    <>
    {/* <FloatingButton/> */}
    <footer className="edu-footer footer-dark bg-image footer-style-2">
            <div className="footer-top">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6">
                            <div className="edu-footer-widget">
                                <div className="logo">
                                                                            <Link onClick={()=>ScrollingopenHandler()} to="/">
                                        <img className="logo-dark" src={dynamiclogo} onError={(e) => {
                                        e.target.src = logoimg;
                                    }} alt="Corporate Logo"/>
                                    </Link>
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: footerhtml }}/>
                                <div className="widget-information">
                                    <ul className="social-share py-3">
                                        <li><a href={`${flink}`} className="color-fb custom-icon-design"><i className="icon-facebook"></i></a></li>
                                        {/* <li><a href={`${flink}`} className="color-linkd custom-icon-design"><i className="icon-linkedin2"></i></a></li> */}
                                        <li><a href={`${ilink}`} className="color-ig custom-icon-design"><i className="icon-instagram"></i></a></li>
                                        <li><a href={`${tlink}`} className="color-twitter custom-icon-design"><i className="icon-twitter"></i></a></li>
                                        <li><a href={`${ylink}`} className="color-yt custom-icon-design"><i className="icon-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="edu-footer-widget explore-widget">
                                <h4 className="widget-title">Website Link</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">

                                       {Footerleft.map((foot1)=>(<li><Link id={trackingid} data-button-id={`Click on the button '${foot1.title}' on the ${pagename} Page at URL: ${window.location.href}.`} onClick={()=>ScrollingopenHandler()} to={validateURL(foot1.linkurl) || foot1.linkurl === '/' ? `${foot1.linkurl}` : `/${foot1.linkurl}`}>{foot1.title}</Link></li>))}
                                        {/* <li><Link to="/about/about-us">About Us</Link></li>
                                        <li><Link to="/about/why-choose-us">Why SRIRAM's IAS</Link></li>
                                        <li><Link to="/about/director-message">Director's Message</Link></li>
                                        <li><Link to="/course">Courses</Link></li>
                                        <li><Link to="#">Optional Courses</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6">
                            <div className="edu-footer-widget quick-link-widget">
                                <h4 className="widget-title">Website Link</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                    {Footerright.map((foot1)=>(<li><Link id={trackingid} data-button-id={`Click on the button '${foot1.title}' on the ${pagename} Page at URL: ${window.location.href}.`} onClick={()=>ScrollingopenHandler()} to={validateURL(foot1.linkurl) ? `${foot1.linkurl}` : `/${foot1.linkurl}`}>{foot1.title}</Link></li>))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="edu-footer-widget">
                                <h4 className="widget-title">Course Details</h4>
                                <div className="inner">
                                    {/* <p className="description">Enter your email address to register to our newsletter subscription</p>
                                    <div className="input-group d-grid footer-subscription-form">
                                        <input type="email" className="form-control w-100 px-3 py-4 mb-2" placeholder="Your email"/>
                                        <button className="edu-btn edu-btn1 btn-medium" type="button" style={{marginLeft: "0px"}}>Subscribe <i className="icon-4"></i></button>
                                    </div> */}
                                    <ul className="footer-link link-hover">
                                        <li onClick={()=>ScrollingopenHandler()}><a href="/course">All Courses</a></li>
                                        {courses.slice(0,7).map((course,index)=>(

                                            <li onClick={()=>ScrollingopenHandler()}><a href={`/course-details/${course.courseurl}`}>{course.coursename}</a></li>
                                        ))}
                                    </ul>
                                    
                                </div>

                                <div style={{ display: "flex", alignItems: "center", paddingTop: "15px" }}>
                                    <a href={androidapp}><img 
                                        src="https://admin.sriramsias.com/images/android-app.png" 
                                        alt="Download Android App" 
                                        style={{ width: "125px", marginRight: "10px" }}
                                    /></a>
                                    <a href={iosapp}><img 
                                        src="https://admin.sriramsias.com/images/ios-app.png" 
                                        alt="Download iOS App" 
                                        style={{ width: "125px" }}
                                    /></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                            <p onClick={()=>ScrollingopenHandler()} style={{paddingBottom: "5px"}}>
                                <Link id={trackingid} data-button-id={`Click on the button privacy-policy on the ${pagename} Page at URL: ${window.location.href}.`} to="/privacy-policy" className='custom-hover edu-btn4 footer-link' style={{color: "rgb(130 125 125)"}}>Privacy Policy</Link> | <Link id={trackingid} data-button-id={`Click on the button refund-policy on the ${pagename} Page at URL: ${window.location.href}.`} to="/refund-policy" className='' style={{color: "rgb(130 125 125)"}}>Refund & Cancellation</Link> | <Link id={trackingid} data-button-id={`Click on the button terms-and-conditions on the ${pagename} Page at URL: ${window.location.href}.`} to="/terms-and-conditions" className='' style={{color: "rgb(130 125 125)"}}>Terms & Conditions</Link></p>
                                {/* <p>{footerstamp}</p> */}
                                <p className='text-white' onClick={()=>ScrollingopenHandler()} dangerouslySetInnerHTML={{ __html: footerstamp }}/>
                                {/* <p>Copyright 2024 <a href="https://www.technologyxtend.com/" style={{color: "#db261f"}} target="_blank">SRIRAM's IAS</a> All Rights Reserved</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer;